.ant-table {
    font-size: .9375rem !important;
    color: rgb(51, 51, 51) !important;
}
.ant-table-body{
  overflow-x: auto !important; 
}
.ant-table-thead > tr > th {
  white-space: nowrap;
    padding: 12px 25px;
    border-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    color: #28084B;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
    border-right: 0px solid #f0f0f0 !important;
    border-top: 1px solid #dee2e6 !important;
}

td.ant-table-column-sort {
    background: rgba(0, 0 ,0 , 0) !important;
}
.ant-table.ant-table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    white-space: nowrap;
}
.ant-table.ant-table td h2 {
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}
.ant-table.ant-table td h2 a {
    color: #333;
}
.ant-table.ant-table td h2 span {
    color: #888;
    display: block;
    font-size: 12px;
    margin-top: 3px;
}
/* .ant-table-thead > tr , .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 10px 8px !important;
} */
.ant-table.ant-table tr  {
    box-shadow: 0 0 3px #e5e5e5;
}
.ant-pagination {
    margin-top: 20px !important;
    text-align: end;
}
.ant-pagination-prev {
    border: 1px solid #dee2e6;
    padding: 1px 10px;
    background-color: #fff;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.ant-pagination-next {
    border: 1px solid #dee2e6;
    padding: 1px 10px;
    background-color: #fff;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus-visible .ant-pagination-item-link{
    border: none !important;
}

.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    color: rgb(108, 117, 125) !important;
    font-family: 'CircularStd', sans-serif !important;
    font-size: 15px !important;
    height: 36px !important;
}
.ant-pagination-prev, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    margin-right: 0px !important;
}
.ant-pagination-item {
    margin-right: 0px !important;
    padding: 1px 0px !important;
    height: 36px !important;
}

.ant-pagination-total-text {
    float: left;
    color: rgb(51, 51, 51) !important;
    font-family: 'CircularStd', sans-serif !important;
    font-size: 15px !important;
}
.ant-pagination-item-active {
    font-weight: 500;
    background: #7539FF;
    border: none !important;
    color: rgb(255, 255, 255) !important;
    z-index: 1 !important;
    width: 34px;
    text-align: center;
}
.ant-table-pagination-right {
    float: none !important;
}

.ant-table-tbody > tr > td {
    /* border-bottom: 0px solid #f0f0f0; */
}
table.table td h2 a {
    color: #333 !important;
}
td.ant-table-cell {
    font-size: 14px;
    font-weight: 400;
    vertical-align: middle !important;
}
.role table > thead > tr:first-child th:last-child {
    border-top-right-radius: 2px;
    text-align-last: end;
}
.card-table .ant-table .table {
    background: #fff;
    margin-bottom: 24px !important;
    border-radius: 8px;
}
.card-table .card-body .ant-table .table th:first-child {
    padding-left: 25px;
}
.card-table .card-body .ant-table .table th {
    white-space: nowrap;
    padding: 12px 25px;
    border-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    color: #28084B;
}
/* .ant-table thead {
    background-color: transparent;
    border-bottom: 0;
    vertical-align: middle;
    white-space: nowrap;
} */
.card-table .ant-table .table{
    background: #fff;
    margin-bottom: 24px !important;
    border-radius: 8px;
}
.anticon {
    display: flex;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.ant-table-column-sorters{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.ant-table-column-sorter-inner{
    font-size: 10px;
}

ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
    display: flex;
 
} 


.ant-table {
    font-size: .9375rem !important;
    color: rgb(51, 51, 51) !important;
}
.ant-table-body{
  overflow-x: auto !important; 
}
/* .ant-table-thead > tr > th {
  background-color: rgba(255, 255, 255, 0.85) !important;
  border-bottom: 0px solid #dee2e6 !important; 
}*/
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td, .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
    border-right: 0px solid #f0f0f0 !important;
    border-top: 1px solid #dee2e6 !important;
}
/* .ant-pagination-options-size-changer.ant-select {
    display: none !important;
} */
td.ant-table-column-sort {
    background: rgba(0, 0 ,0 , 0) !important;
}
.ant-table.ant-table td h2.table-avatar {
    align-items: center;
    display: inline-flex;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    white-space: nowrap;
}
.ant-table.ant-table td h2 {
    display: inline-block;
    font-size: inherit;
    font-weight: 400;
    margin: 0;
    padding: 0;
    vertical-align: middle;
}
.ant-table.ant-table td h2 a {
    color: #333;
}
.ant-table.ant-table td h2 span {
    color: #888;
    display: block;
    font-size: 12px;
    margin-top: 3px;
}
/* .ant-table-thead > tr , .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 0px 8px !important;
} */
.ant-table.ant-table tr  {
    box-shadow: 0 0 3px #e5e5e5;
}
.ant-pagination {
    margin-top: 20px !important;
    text-align: end;
}
.ant-pagination-prev {
    border: 1px solid #dee2e6;
    padding: 1px 10px;
    background-color: #fff;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}
.ant-pagination-next {
    border: 1px solid #dee2e6;
    padding: 1px 10px;
    background-color: #fff;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}
.ant-pagination-disabled .ant-pagination-item-link, .ant-pagination-disabled:hover .ant-pagination-item-link, .ant-pagination-disabled:focus-visible .ant-pagination-item-link{
    border: none !important;
}

.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    color: rgb(108, 117, 125) !important;
    font-family: 'CircularStd', sans-serif !important;
    font-size: 15px !important;
    height: 36px !important;
}
.ant-pagination-prev, .ant-pagination-jump-prev, .ant-pagination-jump-next {
    margin-right: 0px !important;
}
.ant-pagination-item {
    margin-right: 0px !important;
    padding: 1px 0px !important;
    height: 36px !important;
}

.ant-pagination-total-text {
    float: left;
    color: rgb(51, 51, 51) !important;
    font-family: 'CircularStd', sans-serif !important;
    font-size: 15px !important;
}
.ant-pagination-item-active {
    font-weight: 500;
    background: #7539FF!important;
    border: none !important;
    color: rgb(255, 255, 255) !important;
    z-index: 1 !important;
}
.ant-pagination-options {
    display: inline-block;
    margin-left: 16px;
    vertical-align: middle;
}
.ant-pagination::after {
    display: block;
    clear: both;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    content: ' ';
}
.ant-pagination-options-size-changer.ant-select {
    display: inline-block;
    width: auto;
}
.ant-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 32px;
    padding: 0 11px;
}
.ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    right: 11px;
    display: flex;
    align-items: center;
    height: 12px;
    margin-top: -6px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 12px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
}
.ant-table-pagination-right {
    float: none !important;
}

.ant-table-tbody > tr > td {
    /* border-bottom: 0px solid #f0f0f0; */
}
table.table td h2 a {
    color: #333 !important;
}
td.ant-table-cell {
    font-size: 14px;
    font-weight: 400;
    vertical-align: middle !important;
}
.role table > thead > tr:first-child th:last-child {
    border-top-right-radius: 2px;
    text-align-last: end;
}
li.ant-pagination-total-text {
    display: inline-block;
    height: 32px;
    margin-right: 8px;
    line-height: 30px;
    vertical-align: middle;
}
.ant-table-pagination-right {
    justify-content: flex-end;
}
.ant-table-pagination {
    display: flex;
    flex-wrap: wrap;
    row-gap: 8px;
}
.ant-pagination {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
}
.ant-pagination {
    margin-top: 20px !important;
    text-align: end;
}
.ant-pagination-item {
    display: inline-block;
    min-width: 32px;
    height: 32px;
    margin-right: 8px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    list-style: none;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.customers .ant-table-cell{
    text-align: center;
}
.table tbody td h2.table-avatar a {
    color: #3F4254;
    text-align: left;
}
li.ant-pagination-prev.ant-pagination-disabled {
    display: flex;
    align-items: center;
}
li.ant-pagination-next.ant-pagination-disabled {
    display: flex;
    align-items: center;
}
li.ant-pagination-options {
    display: none;
}

.ant-table tbody tr td {
    border-color: #dee2e6;
    color: #878A99;
    font-weight: 400;
    border-top: 0;
    font-size: 13px;
    padding: 15px 25px;
    white-space: nowrap;
    box-shadow: none;
}
.customer-details .ant-table-tbody{
    background-color: #fff;
}
.ant-table-thead{
    background-color: #F3F3F9 !important;
}
.ant-checkbox .ant-checkbox-input{
    height: 19px;
    width: 19px;
    border: 1px solid #C4C4C4;
    background-color: #fff;
    border-radius: 6px;
    transition: all 0.3s ease-in-out;
    cursor: pointer !important;
}

.ant-table-thead.ant-table-cell .ant-table-selection-column{
    white-space: nowrap;
    padding: 12px 5px;
    border-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    color: #28084B;
}
.ant-table-tbody{
    background-color: #fff;
}
.ant-table.ant-table td h2 a {
    color: #333;
    font-weight: 600;
    text-align: initial;
}
.ant-table.ant-table td h2 a:hover{
    color: #7539FF;
}

.dropdown.dropdown-action{
    background: #F3F3F3;
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.ant-table-cell.btn.btn-greys {
    padding: 5px 10px !important;
}
.ant-pagination.ant-table-pagination.ant-table-pagination-right.css-dev-only-do-not-override-1vtf12y
{
    position:relative !important;
}
.ant-pagination-total-text{
position: absolute !important;
left: 28px !important;
}
.react-datepicker-popper {
    z-index: 10 !important;
}