.receipt-wrap {
	max-width: 340px;
    margin: 30px $__auto;
    padding: 30px;
    background-color: $__white;
    .receipt-top {
        text-align: $__center;
        .company-logo {
	        background: $__exchangebg;
	        padding: 10px;
	        text-align: center;
	        margin-bottom: 10px;
	    }
	    .company-name {
	        color: $__black;
	        margin-bottom: 10px;
	    }
	    .company-address,.company-email {
	        font-size: $__font__size__10;
	    }
    }
    .receipt-heading {
            display: $__flex;
		    justify-content: $__center;
		    position: $__relative;
		    text-transform: uppercase;
		    font-size: $__font__size__10;
		    font-weight: $__bold;
		    margin: 10px 0;
		    color: $__black;
        span {
		    display: inline-flex;
		    padding: 0 5px;
		    background-color: $__white;
		    z-index: 1;
		    font-weight: $__bold;
		    font-size: $__font__size__10;
		}
		&:before {
		    content: '';
		    position: $__absolute;
		    height: 0;
		    width: 100%;
		    left: 0;
		    top: 46%;
		    border-top: 1px dashed $__grey__nuetral;
		}
    }
    .customer-list {
	    list-style: $__none;
	    padding: 0;
	    margin: 0;
	    display: $__flex;
	    flex-wrap: wrap;
	    li {
		    display: $__flex;
		    width: 48%;
		    font-size: $__font__size__12;
		    line-height: 1.2em;
		    margin-bottom: 10px;
		    margin-right: 10px;
		    justify-content: space-between;
		    .desc {
		         color: $__black;
		    }
		}
    }
    .receipt-table {
       width: 100%;	    
	    line-height: 1.3em;
	    font-size: $__font__size__12; 
        thead {
           th {
		    color: $__black;
		    text-align: left;
		    padding: 10px 3px;
		    border-top: 1px dashed $__grey__nuetral;
		    border-bottom: 1px dashed $__grey__nuetral;
		   }		   
        }
        td {
           padding: 6px;
        }
        td,th {
           &:first-child {
              padding-left: 0;
           }
        }
        tr {
           &:first-child {
              td {
                  padding-top: 10px;
              }
           }
        }        
    }
    .bill-list {    
       margin: 0;
       padding: 5px 0;
       font-size: $__font__size__10; 
       .bill_list_in {
		    display: $__flex;
		    text-align: $__right;
		    justify-content: space-between;
		    padding: 4px 0;
		    .bill_title {
			   padding-right: 20px;
			}
			.bill_value {
			   width: 90px;
			}
		}
    }
    .total-payable {
       color: $__black;
    }
    .receipt-seperator {
        width: 100%;
	    border-top: 1px dashed $__grey__nuetral;
	    margin: 6px 0;
	    margin-left: auto;
    }
    .sample_text {
	    text-align: center;
	    padding: 10px 0;
	    border-bottom: 1px dashed $__grey__nuetral;
	    line-height: 1.6em;
	    color: $__grey__nuetral;
	    font-size: $__font__size__10;
    }
    .receipt-footer {
	    text-align: center;
	    margin-top: 10px;
	    font-size: $__font__size__10;
    } 
}
