.invoice-wrapper {
    background: $__white;
    @include margin-padding(60px 0 40px, 25px);
    @include rounded(10px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);   
   @include respond-below(custom767) {
      padding: 0;
   } 
}
.invoice-header, .inv-details {
    @extend %display-flex;
    @extend %justify-content-between;
    @extend %align-items-center;
}
.inv-details {
    @include respond-below(custom767) {
        display: $__block;      
    } 
}
.inv-content {   
        
    .invoice-header {
        border-bottom: 1px solid $__invborder__color;
        padding: 30px 30px 15px;
        .inv-header-left {
            a {
               display: $__block;
            }
            img{
                max-width:200px;
            }
            span {
               color: $__grey__nuetral;
            }
        }
        .inv-header-right {
            text-align: right;
            img{
                max-width:200px;
            }
            .invoice-title {
                font-size: $__font__size__24;
                font-weight: $__bold;  
                color:$__black__nuetral;
                @include respond-below(custom992) {
                    font-size: $__font__size__20;
                    @include margin-padding(10px 0 0 0, 0); 
                }          
            }
            .inv-date {
                color: $__grey__nuetral;
                font-size: $__font__size__18;
                font-weight: $__normal;
                margin-right: 20px;
                &:last-child {
                   margin-right: 0;
                }
                span {
                     color: $__black__nuetral;
                     font-weight: $__bold;
                     font-size: $__font__size__24;
                     margin-left: 8px;
                     @include query-print {
                         font-size: $__font__size__14;
                    }
                    @include respond-below(custom992) {
                        font-size: $__font__size__16; 
                    }  
                }
            }
            @include respond-below(custom767) {
                text-align: left;
            }
        }
        @include respond-below(custom767) {
            display: block;
        } 
        @include query-print {
            display: $__flex;
        }
    }
    .invoice-address {
        @include margin-padding(0, 20px 30px); 
        @extend %display-flex;
        @extend %justify-content-spacebetween;
        border-bottom: 1px solid $__invborder__color;
        .invoice-to {
            span {
                color:$__light__gray__light;
                margin: 0 0 10px;
                display: block;
                @include respond-below(custom767) {
                    margin: 0 0 10px;           
                }
                @include respond-below(custom575) {
                    margin: 0 40px  10px;           
                }
            }
            .inv-to-address {
                color: $__primarycolor1;
            }
            @include respond-below(custom767) {
                margin-bottom: 20px;
            }  
        }
        .company-details {
            text-align: $__right;
            .company-name {
                font-size:$__font__size__24;
                color: $__tablehead__bg;
                margin: 0 0 10px;
                font-weight: $__bold;
                display: $__block;   
            }
            .gst-details {
                
                margin: 0 0 10px;
                span {                    
                    color: $__grey__nuetral;;                    
                    display: $__block;
                }
                @include query-print {
                     margin: 0 0 5px;
                }  
            }
            @include respond-below(custom767) {
                text-align: left;
                margin: 0 0 20px;
            } 
        } 
        @include query-print {
            font-size: $__font__size__10;              
        } 
        @include respond-below(custom992) {
            font-size: $__font__size__14; 
        }
        @include respond-below(custom767) {
            display: block; 
            padding: 0px 30px;
        }               
    }
    .invoice-table {
        @include margin-padding(0,15px 30px 0);
        table {
            width: 100%;
            color: $__black__nuetral;
            .table_width_1 {
                width: 3%;
            }
            .table_width_2 {
                width: 20%;
            }
            .table_width_3 {
                width: 40%;
            }
            .table_width_4 {
                width: 15%;
            }
            .table_width_5 {
                width: 15%;
            }
            thead tr {
                background: $__invborder__color;
            }
            tr {
                border-bottom: 1px solid $__invborder__color;
                td, th {
                   padding: 15px;
                    @include query-print {
                        padding: 8px;                      
                    }
                   
                }
               .table-description {
                   color: $__gray__dark;
               }
            }           
        }
        @include query-print {
            font-size: $__font__size__12;            
        }
        @include respond-below(custom767) {
            font-size: $__font__size__14;           
        } 
    }
    .total-amountdetails {
        border-bottom: 1px solid $__invborder__color;
        padding: 15px 30px;
         span {
             color: $__black__nuetral;
        }
        @include query-print {
            font-size: $__font__size__12;
            padding: 5px 30px;    
        }
        @include respond-below(custom575) {
            font-size: $__font__size__14;     
        }  

    }
    .invoice-table-footer {
        @extend %display-flex;
        @extend %align-items-center;
        padding: 5px 30px;
        
        .table-footer-left {
            width: 70%;
            @include respond-below(custom575) {
               width: auto;   
               font-size: $__font__size__14;  
            } 
        }
        .table-footer-right {
            width: 31%; 
            table {
                width: 100%;
                td{
                  text-align: right;
                  width: 50%;
                  padding: 5px 10px;
                  @include query-print {
                     width: auto;
                  } 
                  @include respond-below(custom992) {
                     width: auto; 
                  } 
                  &:first-child {
                    @include respond-below(custom767) {
                        text-align: left; 
                     } 
                  }                  
                }
                @include query-print {
                  font-size: $__font__size__12;
                }  
            }
            .totalamt-table {
                td {
                   color: $__black__nuetral;
                   font-size: $__font__size__24;
                   font-weight: $__bold;
                   @include query-print {
                      font-size: $__font__size__12;
                   }
                   @include respond-below(custom992) {
                      font-size: $__font__size__16;
                   }   
                } 
                @include query-print {
                  font-size: $__font__size__12;
                }                
            }            
            @include respond-below(custom992) {
                width: 60%;
            } 
            @include respond-below(custom767) {
                width: 100%;     
            }
            @include query-print {
                width: 50%;
            }  
        }
        @include query-print {
            font-size: $__font__size__12;
        } 
        .total-info {
             @include respond-below(custom767) {
                display: $__none;     
            }
        }
    }
    .bank-details {
        @extend %display-flex;
        @extend %justify-content-spacebetween;
        @extend %align-items-center;
        @include margin-padding(0, 15px 30px);
        border-bottom: 1px solid $__invborder__color;
        .bank-title {
            margin: 0 0 10px;
            color:$__black__nuetral; 
            font-weight: $__bold;
            font-size: $__font__size__24;  
            display: $__block;  
            @include query-print {
               font-size: $__font__size__12;                
            }     
        }
        .account-details {
            margin: 0 0 5px;
            span {
                 color: $__black__nuetral;
            }
        }
        .company-sign {
            text-align: $__right;
            span {
                display: $__block;
                margin: 0 0 15px;
                color: $__black;
            }
            @include respond-below(custom575) {
                text-align: $__left;         
            }  
        } 
        @include query-print {
            font-size: $__font__size__12; 
            @include margin-padding(0, 10px 30px);
        } 
        @include respond-below(custom767) {
            font-size: $__font__size__14; 
            display: $__block; 
            @include margin-padding(0, 5px 30px);
        }         
    }
    .terms-condition {
        @include margin-padding(0, 15px 30px);
       
        ol {
            list-style: number;
            padding-left: 26px;
            color: $__black__nuetral;            
        }
        span {
            display: $__block;
            margin: 0 0 5px;
        }
        @include query-print {
            font-size: $__font__size__12;  
            @include margin-padding(0, 10px 30px);              
        } 
        @include respond-below(custom575) {
            font-size: $__font__size__14;            
        }  
    }
    .thanks-msg {
        @include margin-padding(0, 15px 0);
        @include query-print {
           font-size: $__font__size__10;  
           @include margin-padding(0, 10px);              
        } 
        @include respond-below(custom575) {
            font-size: $__font__size__12;             
        }  
    }
}

.file-link {
    margin-bottom: 40px;
    display: $__flex;
    align-items: $__center;
    .download-link {
        color: $__downloadcolor;
        @include margin-padding(0px , 12px 22px);
        margin-right: 10px;
        border: 1px solid $__downloadcolor;
        border-radius: 5px;
        background: $__white; 
        height: 46px;  
        &:hover {
            background: $__downloadcolor; 
            color: $__white;
        }          
   }   
   .print-link {
        color: $__printcolor;
        @include margin-padding(0 0 0 0, 15px 22px);        
        border: 1px solid $__printcolor;
        border-radius: 5px;
        height: 46px; 
        background: $__white; 
        display: $__flex;
        align-items: $__center;
        i {
        margin-right: 6px;
        }
        &:hover {
            background: $__printcolor; 
            color: $__white;
        }              
   } 
   @include respond-below(custom992) {
        margin: 20px;
   } 
      
}
.no-stickybar .right-side-views {
    display: none;
}
